.nav {
    
    background-size: cover;
}

.page_wrap {
    margin-top: 50px;
    margin-bottom: 65px;
    font-size: 16px;
    color: #333;

    h2 {
        font-size: 24px;
        color: #333;
        margin-bottom: 40px;
        font-weight: bold;
    }

    h3 {
        font-weight: bold;
        margin: 20px 0;
    }

    p,
    li {
        text-indent: 2rem;
        line-height: 2;
    }
}

@media (max-width:1080px) {
    .page_wrap {
        margin-top: 0;
        margin-bottom: 8.667vw;
        font-size: 3.733vw;

        h2 {
            font-size: 5.333vw;
            margin-top: 1.333vw;
            margin-bottom: 3vw;
        }

        h3 {
            margin: 5.333vw 0;
        }

        p {
            line-height: 2;
        }
    }
}