.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 1103;
  background: rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
}

.success {
  position: relative;
  padding: 55px 22px 22px;
  width: 460px;
  height: 440px;
  background: url("/t_no_word.png") no-repeat center center;
  background-size: contain;
  text-align: center;
  box-sizing: border-box;

  h2 {
    font-size: 24px;
    color: #ffffff;
    margin-bottom: 10px;
  }
  p {
    font-size: 14px;
    color: #fff;
    b {
      color: #ffe848;
    }
  }
  .button1 {
    display: block;
    margin: 198px auto 0;
    background: url("/btn_bg.png") no-repeat center center;
    background-size: contain;
    cursor: pointer;
    width: 268px;
    height: 76px;
    line-height: 76px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #a00000;
  }
  .button2 {
    color: #fff;
    font-size: 16px;
  }
  .qrcodewrap {
    background: #fff;
    border-radius: 12px;
    padding: 10px 10px 30px;

    display: flex;
    flex-direction: column;
    align-items: center;

    position: absolute;
    top: 65px;
    left: 50%;
    transform: translateX(-50%);

    animation: fadein .3s;

    i {
      color: #999999;
      font-size: 16px;
    }
    .close {
        position: absolute;
        width: 35px;
        height: 35px;
        background: url('/close.png') no-repeat center center;
        background-size: contain;
        right: -8px;
        top: -11px;
        padding-left: 0;
    }
  }
}

@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media (max-width: 1080px) {
  .success {
    width: 74vw;
    height: 90vw;
    background-image: url("/t_no_word.png");
    padding: 9.2vw 4vw 4vw;

    h2 {
      font-size: 4.8vw;
      margin-bottom: 1.333vw;
    }
    p {
      font-size: 3.733vw;
    }
    .btn_box {
      margin-top: 40vw;
    }
    .button1 {
      margin-top: 4vw;
      width: 56vw;
      height: 14.133vw;
      line-height: 14.133vw;
      background-image: url("/btn_bg.png");
      font-size: 3.73333333vw;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #a00000;
    }
    .button2 {
      color: #fff;
      opacity: 0.75;
      font-size: 3.73333333vw;
    }
    .qrcodewrap {
        padding: 10px 10px 30px;
    }
  }
}
